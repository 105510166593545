import { createApp } from 'vue'
import { useGiftTemplate, decodeGift } from '@consumer/composables/giftTemplate'
import type GiftTemplate from '@consumer/types/GiftTemplate'
import type { GiftlyDesign } from '@consumer/types/serializers'
import { currentParams, replaceQuery } from '@shared/url'
import { checkout as checkoutApi } from '@consumer/api'
import GiftlyV3Image from '@consumer/components/GiftlyV3Image.vue'

export function setupMiniCustomizer (
  {
    el,
    customAmountModal,
    customAmountModalShadow,
    customAmountTextbox,
    changeCustomAmountButton,
    closeCustomAmountModalButton,
    checkoutButtonSelector,
    initialGift,
    giftlyImageEl,
    prevGiftlyDesignButton,
    nextGiftlyDesignButton,
    revealMoreDesignsButton,
  }: {
    el: HTMLElement
    customAmountModal: HTMLFormElement
    customAmountModalShadow: HTMLElement
    customAmountTextbox: HTMLInputElement
    changeCustomAmountButton: HTMLButtonElement
    closeCustomAmountModalButton: HTMLButtonElement
    checkoutButtonSelector: string
    initialGift: GiftTemplate
    giftlyImageEl: HTMLElement
    prevGiftlyDesignButton: HTMLElement
    nextGiftlyDesignButton: HTMLElement
    revealMoreDesignsButton: HTMLElement
  }) {
  const amountButtonSelector = '[data-amount]'
  const pickerSelector = '[data-design]'
  const giftFromUrl = currentParams().get('gift')
  const gift = $ref<GiftTemplate>(giftFromUrl ? decodeGift(giftFromUrl) : initialGift)
  let currentDesignPickerEl = $ref(el.querySelector<HTMLElement>(`${pickerSelector}.selected`)!)
  const currentDesign = $computed(() => JSON.parse(currentDesignPickerEl.dataset.design!) as GiftlyDesign)
  loadDesign(currentDesign)

  const { giftTemplateParam } = $(useGiftTemplate($$(gift)))

  watch(() => currentDesignPickerEl, (currentDesignPickerEl, previousDesignPickerEl) => {
    // Set the new design.
    gift.giftlyDesignName = currentDesign.id

    // Update the styling for the selected picker.
    previousDesignPickerEl?.classList.remove('selected')
    currentDesignPickerEl.classList.add('selected')
  })

  // Update the current URL whenever the amount or design are customized.
  watch(() => [gift.amount, gift.giftlyDesignName], () =>
    replaceQuery({ giftly_design_name: gift.giftlyDesignName, gift: giftTemplateParam }))

  el.querySelectorAll<HTMLElement>(amountButtonSelector).forEach((button) => {
    button.addEventListener('click', () => selectGiftlyAmount(button))
  })

  function selectGiftlyAmount (amountButtonEl: HTMLElement) {
    const giftCardAmount = amountButtonEl.getAttribute('data-amount')
    if (giftCardAmount === 'Other') {
      openCustomAmountModal()
    }
    else if (giftCardAmount) {
      setGiftCardAmount(parseInt(giftCardAmount))
    }
  }

  function setGiftCardAmount (amount: number) {
    if (!isValidAmount(amount)) return false

    gift.amount = amount

    const customAmountEl = el.querySelector('[data-amount="Other"]')!

    const previousAmountButtonEl = el.querySelector(`${amountButtonSelector}.selected`)
    previousAmountButtonEl?.classList.remove('selected')

    const currentAmountButtonEl = el.querySelector(`[data-amount="${amount.toString()}"]`)
    ;(currentAmountButtonEl || customAmountEl).classList.add('selected')

    customAmountEl.textContent = currentAmountButtonEl ? 'Other' : `$${amount}`

    return true
  }

  function openCustomAmountModal () {
    customAmountTextbox.value = String(gift.amount)
    activateCustomAmountChangeButton(true)
    customAmountModalShadow.classList.toggle('hidden', false)
    customAmountModal.classList.toggle('hidden', false)
    document.body.style.overflowY = document.documentElement.style.overflowY = 'hidden'
    customAmountTextbox.focus()
  }

  function closeCustomAmountModal () {
    customAmountModalShadow.classList.toggle('hidden', true)
    customAmountModal.classList.toggle('hidden', true)
    document.body.style.overflowY = document.documentElement.style.overflowY = ''
  }

  function activateCustomAmountChangeButton (shouldActivate: boolean) {
    customAmountModal.disabled = !shouldActivate
    changeCustomAmountButton.disabled = !shouldActivate
  }

  function customAmountChanged () {
    if (!/^[0-9]+$/.test(customAmountTextbox.value)) {
      activateCustomAmountChangeButton(false)
      return
    }

    const numericValue = parseInt(customAmountTextbox.value)
    if (!isValidAmount(numericValue)) {
      activateCustomAmountChangeButton(false)
      return
    }

    activateCustomAmountChangeButton(true)
  }

  function preventNonNumericInput (event: KeyboardEvent) {
    const { key } = event
    if (key && !(key.match(/^[0-9]/) || key === 'Enter')) {
      event.preventDefault()
    }
  }

  function isValidAmount (amount: number) {
    return amount >= 5 && amount <= 1000
  }

  function setCustomAmount (event: SubmitEvent) {
    event.preventDefault()
    if (setGiftCardAmount(parseInt(customAmountTextbox.value))) {
      closeCustomAmountModal()
    }
  }

  function checkout () {
    const query = { gift: giftTemplateParam, purchaseMethod: 'giftcard' }
    location.href = checkoutApi.create.path({ query })
  }

  async function initializeMiniCustomizer () {
    const initialAmount = gift.amount?.toString()

    if (initialAmount) {
      setGiftCardAmount(parseInt(initialAmount))
    }

    watch(() => gift.giftlyDesignName!, () => { loadDesign(currentDesign) })

    const GcdpGiftlyImage = defineComponent({
      name: 'GcdpGiftlyImage',
      render () {
        return h(GiftlyV3Image, {
          gift,
          inCustomizer: true,
          onImageLoaded (event: Event) {
            switchDesign(currentDesign)

            // Replace the server-rendered Consumer::Images::GiftlyV3 with the
            // GiftlyV3Image Vue component only once the image has loaded.
            if (giftlyImageEl.classList.contains('hidden')) {
              giftlyImageEl.nextElementSibling?.replaceWith(giftlyImageEl)
              giftlyImageEl.classList.remove('hidden')
            }
          },
        })
      },
    })

    createApp(GcdpGiftlyImage).mount(giftlyImageEl)
  }

  customAmountModal.addEventListener('submit', setCustomAmount)
  closeCustomAmountModalButton.addEventListener('click', closeCustomAmountModal)
  customAmountTextbox.addEventListener('input', customAmountChanged)
  customAmountTextbox?.addEventListener('keypress', preventNonNumericInput)
  el.querySelectorAll(checkoutButtonSelector).forEach((button) => {
    button.addEventListener('click', checkout)
  })

  function revealMoreDesigns () {
    el.querySelectorAll(`${pickerSelector}.hidden`).forEach((design, index) => {
      const wrapper = design.parentElement!
      if (index === 0) {
        design.classList.add('fade-in')
        revealMoreDesignsButton.replaceWith(design)
        wrapper.remove()
      }
      else {
        wrapper.classList.remove('hidden')
      }
      design.classList.remove('hidden')
    })
  }

  function selectGiftlyDesign (designPickerEl: HTMLElement) {
    currentDesignPickerEl = designPickerEl
  }

  function switchDesignBy (by: number) {
    const visibleDesigns = [...el.querySelectorAll<HTMLElement>(`${pickerSelector}:not(.hidden)`)]
    const currentIndex = visibleDesigns.findIndex(el => el === currentDesignPickerEl)
    const newIndex = (currentIndex + by + visibleDesigns.length) % visibleDesigns.length
    selectGiftlyDesign(visibleDesigns[newIndex])
  }

  function loadDesign (design: GiftlyDesign) {
    gift.backgroundImageUrl = design.backgroundImageUrl || null as any

    // Switch immediately if there's no background image.
    if (!design.backgroundImageUrl) {
      switchDesign(design)
    }

    // Change the background color. NOTE: We will A/B test this separately.
    // if (el) {
    //   const bgColor = design.backgroundColor || design.maskColor
    //   el.style.backgroundColor = `color-mix(in oklab, ${bgColor}, #F9F9F9 90%)`
    // }
  }

  function switchDesign (design: GiftlyDesign) {
    const meta = gift.giftlyMetadata ||= {}
    meta.maskColor = design.maskColor
    meta.maskName = design.maskName
    meta.logoColor = design.logoColor
    meta.backgroundColor = design.backgroundColor || null as any
  }

  el.querySelectorAll<HTMLElement>(pickerSelector).forEach((designButton) => {
    designButton.addEventListener('click', () => { selectGiftlyDesign(designButton) })
  })
  prevGiftlyDesignButton.addEventListener('click', () => switchDesignBy(-1))
  nextGiftlyDesignButton.addEventListener('click', () => switchDesignBy(1))
  revealMoreDesignsButton.addEventListener('click', revealMoreDesigns)

  initializeMiniCustomizer()
}
