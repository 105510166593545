import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export type SwiperOptions = {
  slidesPerView: number
  spaceBetween: number
  navigation: {
    nextEl: string
    prevEl: string
  }
  breakpoints: {
    640: {
      slidesPerView: number
      spaceBetween: number
    }
    768: {
      slidesPerView: number
      spaceBetween: number
    }
    1024: {
      slidesPerView: number
      spaceBetween: number
    }
  }
}

function noop (event: Event) {
  event.stopPropagation()
}

SwiperCore.use([Autoplay, Navigation, Pagination])
export function setupSwiper (swiperContainer: string, options: SwiperOptions) {
  // Prevent flicking to the next slide when clicking a CTA.
  document.querySelector(swiperContainer)?.querySelectorAll('.v2-button').forEach((buttonEl) => {
    ['touchstart', 'pointerdown'].forEach(eventName => buttonEl.addEventListener(eventName, noop))
  })

  return new SwiperCore(swiperContainer, options)
}
