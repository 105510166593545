import { onClickOutside } from '@vueuse/core'

export function setupBreadcrumbsDropdown (
  {
    dropdownButton,
    dropdownMenu,
  }: {
    dropdownButton: HTMLElement
    dropdownMenu: HTMLElement
  }) {
  let stopClickOutside: ReturnType<typeof onClickOutside>
  let dropdownVisible = false

  function hideDropdown () {
    displayDropdown(false)
  }

  function toggleDropdown () {
    displayDropdown(!dropdownVisible)
  }

  function displayDropdown (display: boolean) {
    dropdownVisible = display
    if (display) {
      dropdownMenu.classList.remove('hidden')
      stopClickOutside = onClickOutside(dropdownMenu, hideDropdown, { ignore: [dropdownButton] })
    }
    else {
      stopClickOutside?.()
      dropdownMenu.classList.add('hidden')
    }
  }

  dropdownButton.addEventListener('click', toggleDropdown)
}
